<template>
  <div class="Result">
    <div class="Result_box" v-if="finishOrWait">
      <van-image fit="cover" :src="require('@/assets/img/reply.png')" />
      <h4>申请成功</h4>
      <div class="Result_content">
        <p>申请证明以通过邮件发送至您的邮箱</p>
        <p>如未收到请联系中智薪税</p>
        <p>客服电话 <a class="blue" href="tel:400-100-6621">400-100-6621</a></p>
      </div>
    </div>
    <div class="Result_box" v-else>
      <van-image fit="cover" :src="require('@/assets/img/wait.png')" />
      <h4>等待处理</h4>
      <div class="Result_content">
        <p>已提交申请，等待系统处理</p>
        <p>我们已将您的申请提交相关部门</p>
        <p>
          您可以通过公众号中的<span class="blue">个人中心</span>查看进度及结果
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Image as VanImage } from "vant";
export default {
  props: {
    finishOrWait: {
      default: false,
    },
  },
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
  },
};
</script>
<style lang="scss" scoped>
.Result {
  width: 100%;
  height: 100%;
  background: #fff;
  .Result_box {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    ::v-deep .van-image {
      width: 150px;
    }

    h4 {
      font-size: 25px;
      padding: 8px 0;
    }
    .Result_content {
      font-size: 12px;
      // color: #afacac;
      .blue {
        color: blue;
      }
    }
  }
}
</style>
